import React from 'react';
import { LocationTypes } from '../../constants';
import * as Styled from './locationTableStyle';
import { useTranslation } from 'react-i18next';
import { addTrailingSlash, isExternalUrlHref, isValidURLLanguage } from '../../utils';
import { useLocation } from '@reach/router';

const LocationTable = ({ Object }) => {
  const [translation] = useTranslation();
  let phones;
  const offeringObject = (data) => {
    const offerings = [
      {
        boolean: data?.node?.hasHeatingOil,
        name: translation('HeatingOil'),
      },
      {
        boolean: data?.node?.hasDiesel,
        name: translation('Diesel'),
      },
      {
        boolean: data?.node?.hasMarkedDiesel,
        name: translation('MarkedDiesel'),
      },
      {
        boolean: data?.node?.hasGasoline,
        name: translation('Gasoline'),
      },
      {
        boolean: data?.node?.hasPropane,
        name: translation('Propane'),
      },
      {
        boolean: data?.node?.hasLubricants,
        name: translation('Lubricants'),
      },
      {
        boolean: data?.node?.hasHeatingEquipment,
        name: translation('HeatingEquipment'),
      },
      {
        boolean: data?.node?.hasDef,
        name: translation('Def'),
      },
      {
        boolean: data?.node?.hasShowers,
        name: translation('Showers'),
      },
      {
        boolean: data?.node?.hasRestrooms,
        name: translation('Restroom'),
      },
      {
        boolean: data?.node?.hasWifi,
        name: translation('Wi-Fi'),
      },
      {
        boolean: data?.node?.hasParking,
        name: translation('Parking'),
      },
      {
        boolean: data?.node?.hasRestaurant,
        name: translation('Restaurant'),
      },
      {
        boolean: data?.node?.hasConvenienceStore,
        name: translation('ConvenienceStore'),
      },
      {
        boolean: data?.node?.hasLunchCounter,
        name: translation('LunchCounter'),
      },
      {
        boolean: data?.node?.hasDriversLounge,
        name: translation('DriversLounge'),
      },
      {
        boolean: data?.node?.hasMultiServices,
        name: translation('MultiServices'),
      },
    ];
    return offerings;
  };
  const StringGenerator = (object) => {
    let header = '';
    let length = 0;
    object.map((item) => {
      if (item.boolean) {
        if (length !== 0) {
          header += ', ' + item.name;
          length++;
        } else {
          header += item.name;
          length++;
        }
      }
    });
    return header;
  };
  const typeObject = {
    Branch: translation('LocalBranch'),
    Cardlock: translation('UltramarCardlock'),
    Pipeline: translation('PipelineCardlock'),
  };

  const location = useLocation();
  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);
  const appendLanguageToURL = isValidURLLanguage(urlLanguage) && urlLanguage;

  return (
    <Styled.TableContainer>
      <Styled.LocationTable striped borderless>
        <Styled.THead>
          <tr>
            <th style={{ width: '20%', border: '0' }}>
              {translation('Address')}
            </th>
            <th style={{ width: '15%', border: '0' }}>
              {translation('Phone')}
            </th>
            <th style={{ width: '15%', border: '0' }}>
              {translation('Hours')}
            </th>
            <th style={{ width: '10%', border: '0' }}>{translation('Type')}</th>
            <th style={{ width: '40%', border: '0' }}>
              {translation('Offerings')}
            </th>
          </tr>
        </Styled.THead>
        <Styled.TBody>
          {Object?.sort((a, b) =>
            a.node.location.localeCompare(b.node.location)
          ).map((item, index) => (
            <tr key={index}>
              <Styled.Td>
                <Styled.LinkStyle
                  to={
                    isExternalUrlHref(item?.node?.urlSlug)
                      ? item?.node?.urlSlug
                      : addTrailingSlash(process.env.GATSBY_DOMAIN_URL +
                        (appendLanguageToURL === 'fr'
                          ? '/fr/sites/details/'
                          : '/en/locations/details/') +
                        item?.node?.urlSlug)
                  }
                >
                  {item?.node?.location}
                </Styled.LinkStyle>
                <br />
                {item?.node?.address1}
                <br />
                {item?.node?.city},&nbsp;{item?.node?.province}&nbsp;
                {item?.node?.postalCode}
              </Styled.Td>
              <Styled.Td>
                {
                  ((phones = item?.node?.phone?.split(' ')),
                  phones.map((phone, index) => (
                    <Styled.LinkStyle2
                      key={index}
                      href={`tel:${phone}`}
                      tabindex="0"
                    >
                      {phone}
                    </Styled.LinkStyle2>
                  )))
                }
              </Styled.Td>
              <Styled.Td>
                {(item?.node?.pinType?.length > 1 ||
                  (item?.node?.pinType?.length === 1 &&
                    item?.node?.pinType[0]?.type !==
                      LocationTypes.PipelineCardlock &&
                    item?.node?.pinType[0]?.type !==
                      LocationTypes.UltramarCardlock)) && (
                  <>
                    <strong>{translation('Branch')}:</strong>
                    <br />
                    {item?.node?.branchHours}
                    <br />
                  </>
                )}

                {item.node.cardlockHours && (
                  <>
                    <strong>{translation('Cardlock')}: </strong>
                    {item?.node?.cardlockHours}
                  </>
                )}
              </Styled.Td>
              <Styled.Td>
                {item?.node?.pinType?.map((locationType, index) => (
                  <div key={index}>{typeObject[locationType.type]}</div>
                ))}
              </Styled.Td>
              <Styled.Td>{StringGenerator(offeringObject(item))}</Styled.Td>
            </tr>
          ))}
        </Styled.TBody>
      </Styled.LocationTable>
    </Styled.TableContainer>
  );
};
export default LocationTable;
